<template>
  <div class="contactpage">
    <div id="app">
      <div class="tables-wrapper">
        <div class="contact">
          <p class="name">{{ nazov }}</p>
          <p>{{ adresa }}</p>
          <p>
            <a href="mailto:{{ kontakt }}">{{ kontakt }}</a>
          </p>
          <p>{{ ICO }}</p>
          <p>{{ ICODPH }}</p>
          <p>{{ DIC }}</p>
          <p>{{ zapisane }}</p>
          <p>{{ prevadzka }}</p>
        </div>

        <div class="places-to-buy">
          <p class="name">Miesta kde nájdete naše produkty:</p>
          <div v-for="(place, index) in placesToBuy" :key="index">
            <p>{{ place.name }}</p>
          </div>
        </div>
      </div>
      <div class="map-container">
        <the-map />
      </div>
    </div>
    <div class="footer-wrapper">
      <the-footer />
    </div>
  </div>
</template>

<style scoped>
*,
*::after,
*::before {
  box-sizing: border-box;
}

.contactpage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 20px;
  background-image: url("../assets/Images/IMIDZ_6.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact,
.places-to-buy {
  border-radius: 4px;
  border: 3px solid #faedcd;
  margin: auto;
  width: 600px;
  background-color: rgba(27, 27, 27);
  padding: 50px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: "Montserrat";
  margin-top: 60px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 300;
}

.name {
  padding-top: 0px;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

.contact h2 {
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

a {
  color: #ffffff;
}

@media (max-width: 780px) {
  .contact,
  .places-to-buy {
    width: 95%;
  }
  .tables-wrapper {
    margin-top: 80px;
  }
}

.map-container {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 780px) {
  .tables-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 50px;
    margin-bottom: 20px;
    width: 80%;
  }
}
</style>

<script>
import TheFooter from "../components/TheFooter.vue";
import TheMap from "../components/TheMap.vue";
export default {
  components: {
    TheFooter: TheFooter,
    TheMap: TheMap,
  },
  data() {
    return {
      nazov: "Jamavi, s.r.o.",
      adresa: "Červenej armády 581, 906 32 Jablonica",
      ICO: "IČO: 56423594",
      DIC: "DIČ: 2122307638",
      zapisane:
        "Spoločnosť je zapísaná v Obchodnom registri Okresného súdu Trnava, vložka číslo: 57285/T",
      kontakt: "masovyroba@jamavi.sk",
      placesToBuy: [
        {
          name: "Sieť predajní Starý Otec, a.s",
        },
        {
          name: "Mäsovýroba Jablonica",
        },
      ],
    };
  },
};
</script>