<template>
  <div class="products">
    <the-product-list :products="products" />
    <div class="footer-wrapper">
      <the-footer />
    </div>
  </div>
</template>


<script>
import TheFooter from "@/components/TheFooter.vue";
import TheProductList from "../components/TheProductsList.vue";
import json from "../../public/butchery.json";
//import ProductService from "../services/Products.js";
export default {
  components: {
    TheFooter,
    TheProductList,
  },
  data() {
    return {
      products: json, // Empty array to store the fetched products
    };
  },
  mounted() {
    this.fetchProducts(); // Fetch products when the component is mounted
  },
  methods: {
    fetchProducts() {
      this.products = json;
      /*ProductService.getAll()
        .then((response) => {
          this.products = response.data;
        })
        .catch((error) => {
          console.error(error);
        });*/
    },
  },
};
</script>

<style scoped>
.products {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/Images/IMIDZ_6.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  overflow-y: auto;
  overflow-x: hidden;
}
.footer-wrapper {
  margin-top: 0;
  flex-shrink: 0;
}
</style>