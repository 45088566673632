<template>
  <div class="aboutpage">
    <div class="container">
      <div class="section">
        <h2>NAŠE POSLANIE, NÁŠ ZÁMER</h2>
        <p>
          Vrátiť tradičnú kvalitu a chuť mäsových výrobkov na naše stoly, ktoré
          pamätajú naši rodičia a starí rodičia z čias domácich zabíjačiek, a
          nebáť sa ponúknuť tieto mäsové výrobky našim najbližším. Kúpou našich
          mäsových výrobkov sa vrátite do doby, keď ešte všetko chutilo a voňalo
          mäsom, ktoré je dochutené prírodnými koreninami a neobsahuje prídavné
          látky ako zvýrazňovače chuti, sóju, separáty, ani iné zbytočné
          chemické prímesi.
        </p>

        <div class="mission-image-gallery">
          <img src="../assets/About/image1.jpg" alt="mission image 1" />
          <img src="../assets/About/image2.jpg" alt="mission image 2" />
          <img src="../assets/About/image3.jpg" alt="mission image 3" />
          <img src="../assets/About/image4.jpg" alt="mission image 4" />
          <img src="../assets/About/image5.jpg" alt="mission image 5" />
          <img src="../assets/About/image6.jpg" alt="mission image 6" />
          <!--<img src="../assets/About/image7.jpg" alt="mission image 4" />-->
          <img src="../assets/About/image8.jpg" alt="mission image 8" />
        </div>
      </div>

      <div class="section">
        <h2>NAŠA HISTÓRIA A PLÁNY</h2>
        <p>
          Vďaka vízii ľudí, ktorý chceli ľuďom priniesť zdravšie a kvalitnejšie
          potraviny, vznikla v roku 2015 nová registrovaná mäsovýroba v
          Jablonici. V roku 2017 vďaka expanzii hlavného partnerského odberateľa
          spoločnosti Starý Otec, a.s. a navýšeniu výroby prešla mäsovýroba
          rekonštrukciou, po ktorej splnila najprísnejšie hygienické požiadavky
          na získanie oválneho razítka. Partnerská spolupráca pretrváva dodnes.
        </p>
        <p>
          Od augusta 2024 sa premenovala spoločnosť na JaMaVi s.r.o. Názov
          JaMaVi symbolizuje mladú rodinu Ján, Mária a dcéra Viktória, ktorí
          pokračujú v zachovaní tradičného mäsiarskeho remesla. Naše plány do
          budúcna sú vybudovať si vlastnú malú sieť predajní s prvou predajňou v
          Jablonici.
        </p>
      </div>

      <div class="section">
        <h2>POLITIKA KVALITY</h2>
        <ul>
          <li>
            Ponúknuť zákazníkovi tradičné domáce mäsové výrobky, ktoré sú
            zároveň zdravotne a hygienicky nezávadné a vyrobené v súlade s
            HACCP.
          </li>
          <li>
            Uvedomujeme si, že kvalitné a čerstvé vstupné suroviny sú základným
            pilierom kvality našich mäsových výrobkov.
          </li>
          <li>
            Dodržiavanie výrobných a technologických postupov s cieľom
            zabezpečiť vám vždy mäsový výrobok v rovnakej očakávanej kvalite.
          </li>
          <li>
            Vyvíjať kvalitné mäsové výrobky s minimálnym obsahom prídavných
            látok je pre nás nikdy nekončiaci proces.
          </li>
          <li>
            Vyrábať poctivé tradičné mäsové výrobky, kde zloženie a chuť bude
            poznávacím znakom pre našich zákazníkov.
          </li>
          <li>
            Zachovanie maloobjemovej remeselnej výroby, kde sa kladie dôraz na
            kvalitu a nie kvantitu.
          </li>
        </ul>
      </div>

      <div class="text-image">
        <div class="text-container">
          <div class="content">
            <p>
              Naša mäsovýroba je situovaná v rázovitej obci Jablonica odkiaľ
              pochádza množstvo našich tradičných receptúr.
            </p>
            <div class="image-container">
              <img src="../assets/About/jablonica.jpg" alt="pekar" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-image">
        <div class="text-container">
          <div class="content">
            <div class="image-container">
              <img src="../assets/About/IMIDZ_2.jpg" alt="pekar" />
            </div>
            <p>
              Snažíme sa o zachovanie poctivého mäsiarskeho remesla. Pre nás je
              na prvom mieste kvalita a nie kvantita. Vyrábame v menších sériách
              ale s dôrazom na výber surovín, korenín až po finálny produkt.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrapper">
      <the-footer />
    </div>
  </div>
</template>

<script>
import TheFooter from "../components/TheFooter.vue";

export default {
  components: {
    TheFooter: TheFooter,
  },
};
</script>

<style scoped>
.aboutpage {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: "Montserrat";
}

.container {
  background-image: url("../assets/Images/IMIDZ_6.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 50px;
  align-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.text-image {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  background-color: rgba(27, 27, 27, 0.95);
  color: #fff;
  border-radius: 15px;
  padding: 30px;
  border: 3px solid #faedcd;
  width: 70%;
}

.content {
  display: flex;
  justify-content: space-between;
}

.text-container {
  display: flex;
  text-align: center;
}

p {
  font-size: 1.8rem;
  line-height: 1.5;
  padding: 1rem;
  text-align: center;
  font-family: "Montserrat";
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  height: 200px;
  border-radius: 10px;
  max-width: 280px;
}

.footer-wrapper {
  margin-top: 0;
  flex-shrink: 0;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .text-image {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .content {
    flex-direction: column; /* Stack the image and text vertically on mobile */
  }

  .image-container {
    margin-bottom: 10px;
  }
}

/* Media query for PC view */
@media (min-width: 769px) {
  .text-image {
    flex-direction: row;
  }

  .image-container {
    margin-right: 20px;
  }
}

.section {
  background-color: rgba(27, 27, 27, 0.95);
  color: #fff;
  border-radius: 15px;
  padding: 30px;
  margin-top: 30px;
  width: 70%;
  border: 3px solid #faedcd;
  text-align: center;
}

.section h2 {
  font-family: "Montserrat";
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.section p,
.section ul {
  font-size: 1.1rem;
  line-height: 1.6;
}

.section ul {
  list-style: none;
  padding-left: 0;
}

.section li {
  margin-bottom: 10px;
}

/* Add media queries to handle responsiveness if needed */
@media (max-width: 768px) {
  .section {
    width: 90%;
  }
}

/* Styling for image gallery */
.mission-image-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

.mission-image-gallery img {
  height: 200px;
  width: auto;
  border-radius: 10px;
  border: 2px solid #9f000d;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .section {
    width: 90%;
  }

  .mission-image-gallery {
    justify-content: space-around;
  }

  .mission-image-gallery img {
    width: 90px;
    height: 90px;
  }
}
</style>