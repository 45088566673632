<template>
  <div>
    <router-link v-if="showPrevArrow" :to="prevProductRoute" class="arrow-left">
      <span class="left-shadow">&lt;</span>
    </router-link>
    <router-link
      v-if="showNextArrow"
      :to="nextProductRoute"
      class="arrow-right"
    >
      <span class="right-shadow">&gt;</span>
    </router-link>
    <div class="container" v-if="product">
      <div class="product-detail-wrapper">
        <div class="product-content">
          <div class="image-wrapper">
            <img :src="getProductImagePath(product)" alt="Product Image" />
          </div>
          <div class="text-wrapper">
            <h1>
              {{ product.nazov }}
              <span class="druh-produkt">{{
                product.kategoria == "párkoviny" ? "mäsa" : product.kategoria
              }}</span>
            </h1>
            <br />
            <p>Zloženie: {{ product.zlozenie }}</p>
            <br />
            <p>Jednotka: {{ product.mj }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <p class="loading">Loading...</p>
    </div>
    <div class="footer-wrapper">
      <the-footer />
    </div>
  </div>
</template>


<script>
import TheFooter from "../components/TheFooter.vue";
//import ProductService from "../services/Products.js";
import json from "../../public/butchery.json";
export default {
  components: {
    TheFooter: TheFooter,
  },
  data() {
    return {
      product: null, // Placeholder for the product data
    };
  },
  mounted() {
    this.fetchProductDetails(this.$route.params.productId);
  },
  watch: {
    $route(to, from) {
      if (to.params.productId !== from.params.productId) {
        this.fetchProductDetails(to.params.productId);
      }
    },
  },
  computed: {
    prevProductRoute() {
      const prevId = this.getPreviousProductId();
      return prevId ? `/product/${prevId}` : null;
    },
    nextProductRoute() {
      const nextId = this.getNextProductId();
      return nextId ? `/product/${nextId}` : null;
    },
    showPrevArrow() {
      return !!this.getPreviousProductId();
    },
    showNextArrow() {
      return !!this.getNextProductId();
    },
  },

  methods: {
    getPreviousProductId() {
      const productId = this.$route.params.productId;
      const currentIndex = json.findIndex((product) => product.id == productId);
      return currentIndex > 0 ? json[currentIndex - 1].id : null;
    },
    getNextProductId() {
      const productId = this.$route.params.productId;
      const currentIndex = json.findIndex((product) => product.id == productId);
      return currentIndex < json.length - 1 ? json[currentIndex + 1].id : null;
    },
    fetchProductDetails(productId) {
      this.product = json.find((product) => product.id == productId);
      /*ProductService.get(productId)
        .then((response) => {
          this.product = response.data;
        })
        .catch((error) => {
          console.error(error);
        });*/
    },
    getProductImagePath(product) {
      if (product.fotka) {
        try {
          return require(`@/assets/Products/${product.fotka}`); // Load image dynamically
        } catch (e) {
          return require(`@/assets/Products/EMPTY.jpg`); // Provide a default image path or handle the case where product.image is undefined
        }
      } else {
        return require(`@/assets/Products/EMPTY.jpg`); // Provide a default image path or handle the case where product.image is undefined
      }
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  flex-direction: column;
  display: flex;
  font-family: "Montserrat";
  font-size: 20px;

  background-image: url("../assets/Images/IMIDZ_6.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;
}

.container:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
}

.product-detail-wrapper {
  margin-top: 0;
  flex-shrink: 0;
  background-color: rgba(27, 27, 27);
  color: white;
  padding: 40px;
  border-radius: 10px;
  border: 3px solid #faedcd;
  width: 70%;
}

.product-content {
  display: flex;
}

.druh-produkt {
  font-size: 20px;
  font-weight: 200;
}

.image-wrapper {
  flex: 1;
  text-align: left;
}

.text-wrapper {
  padding-left: 20px;
  flex: 1;
  text-align: left;
  min-width: 200px;
}

.footer-wrapper {
  margin-top: 0;
  flex-shrink: 0;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px;
}

@media (max-width: 767px) {
  .product-content {
    flex-direction: column;
  }

  .image-wrapper,
  .text-wrapper {
    width: 100%;
  }

  .image-wrapper img {
    border-radius: 0;
  }
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    justify-content: top;
    align-items: top;
  }
  .arrow-left,
  .arrow-right {
    display: none;
  }
}

.loading {
  color: rgba(27, 27, 27);
  font-size: 30px;
  font-weight: 200;
  margin-top: 50px;
  font-family: "Montserrat";
}

.arrow-left,
.arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 80px;
  color: #ffffff;
  opacity: 0.5;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  z-index: 1;
  text-decoration: none;
}

.arrow-left:hover,
.arrow-right:hover {
  color: #fffdfd;
  opacity: 0.9;
}

.arrow-left {
  left: 0;
}

.arrow-right {
  right: 0;
}

.right-shadow {
  padding: 30px;
  padding-top: 50vh;
  padding-bottom: 50vh;
  top: 0;
  right: 0;
  width: 30px;
  height: 200px;
  background: linear-gradient(
    to left,
    rgba(27, 27, 27, 0.9),
    rgba(27, 27, 27, 0)
  );
}

.left-shadow {
  padding: 30px;
  padding-top: 50vh;
  padding-bottom: 50vh;
  top: 0;
  left: 0;
  width: 30px;
  height: 200px;
  background: linear-gradient(
    to right,
    rgba(27, 27, 27, 0.9),
    rgba(27, 27, 27, 0)
  );
}
</style>