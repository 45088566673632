<template>
  <div class="product-card">
    <div id="container">
      <div class="product-details">
        <div class="image-card">
          <img :src="imageSrc" alt="" />
        </div>
        <div class="text-container">
          <h1>{{ headline }}</h1>
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headline: String,
    text: String,
    imageSrc: String,
  },
};
</script>

<style scoped>
#container {
  box-shadow: 0 15px 30px 1px grey;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 0.5em auto;
  height: auto;
  width: 60%;
  font-family: "Montserrat";
  align-items: center;
  color: rgba(27, 27, 27, 1);
}

#container h1 {
  font-size: 3vw; /* Use vw (viewport width) units for font size */
  padding-bottom: 0.3em;
}

#container p {
  font-size: 2vw; /* Use vw (viewport width) units for font size */
}

.product-details {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
}

.image-card {
  flex-basis: 40%;
  text-align: left;
  margin: 0;
  padding: 0;
}
.image-card img {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.text-container {
  flex-basis: 60%;
  text-align: left;
  padding-left: 1em;
  padding-top: 1em;
  padding-bottom: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .image-card {
    animation-name: move;
    animation-duration: 2s;
  }
}

@keyframes move {
  0% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(160%);
  }
  100% {
    transform: translateX(0px);
  }
}

/* Mobile Styles */
@media only screen and (max-width: 767px) {
  #container {
    width: 90%;
  }

  .product-details {
    align-items: flex-start;
    flex-direction: column;
  }

  .image-container {
    flex-basis: 100%;
    margin-bottom: 1em;
  }

  .text-container {
    flex-basis: 100%;
    padding: 0;
  }

  #container h1 {
    font-size: 10vw;
    text-align: center;
  }

  #container p {
    font-size: 4vw;
    text-align: center;
    padding: 10px;
  }
}
</style>