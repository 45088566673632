<template>
  <transition :name="transitionEffect">
    <div
      class="carousel-item"
      v-show="currentSlide === index"
      @mouseenter="$emit('mouseenter')"
      @mouseout="$emit('mouseout')"
    >
      <img class="slide" :src="slide" />
      <div class="description">
        <img
          class="logo"
          src="../../assets/RMJ_logo_white.png"
          alt="Company Logo"
        />
        <!--<h3 v-if="isDesktop">{{ description.title }}</h3>
        <p v-if="isDesktop" v-html="highlightText(description.text)"></p>-->
        <p v-if="isDesktop" v-html="description.text"></p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  emits: ["mouseenter", "mouseout"],
  props: ["slide", "currentSlide", "index", "direction", "description"],
  computed: {
    transitionEffect() {
      return this.direction === "right" ? "slide-out" : "slide-in";
    },
    isDesktop() {
      return window.innerWidth >= 769; // Check if the screen width is equal to or larger than 769px
    },
  },
  methods: {
    highlightText(text) {
      const regex = new RegExp(this.description.highlight, "gi");
      const highlightedText = text.replace(
        regex,
        `<span class="highlighted animated">${this.description.highlight}</span>`
      );
      return highlightedText;
    },
  },
};
</script>

<style scoped>
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
  transition: all 1s ease-in-out;
}
.slide-in-enter-from {
  transform: translateX(-100%);
}
.slide-in-leave-to {
  transform: translateX(100%);
}
.slide-out-enter-from {
  transform: translateX(100%);
}
.slide-out-leave-to {
  transform: translateX(-100%);
}

.slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
  opacity: 0.5;
}

.logo {
  width: 18vw;
  height: auto;
  object-fit: cover;
}

.description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-family: "Montserrat";
  white-space: pre-wrap;
  word-break: keep-all;
}

.description p {
  font-size: 1.6vw;
  line-height: 1.5em;
  margin: 0;
  padding: 0;
}

::v-deep .highlighted {
  display: inline-block;
  color: #9f000d;
  font-size: 1.2em;
  white-space: pre-wrap;
  word-break: keep-all;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .logo {
    width: 50vw;
  }
}
</style>
