<template>
  <div>
    <div class="home-component">
      <ThePhotoSlider />
      <!--<TheModal />-->
    </div>
    <div class="footer-wrapper">
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheFooter from "../components/TheFooter.vue";
import ThePhotoSlider from "../components/ThePhotoSlider.vue";
//import TheModal from "../components/TheModal.vue";
export default {
  components: {
    TheFooter,
    ThePhotoSlider,
    //TheModal,
  },
};
</script>

<style scoped>
.home-component {
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  display: flex;
}

.footer-wrapper {
  margin-top: 0;
  flex-shrink: 0;
}
</style>