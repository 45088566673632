<template>
  <photo-slider
    :slides="slides"
    :controls="controls"
    :indicators="indicators"
    :interval="interval"
    :description="description"
  ></photo-slider>
</template>

<script>
import PhotoSlider from "./PhotoSliderComponents/PhotoSlider.vue";
import slider1 from "../assets/PhotoSlider/IMIDZ.jpg";
import slider2 from "../assets/PhotoSlider/IMIDZ_3.jpg";
import slider3 from "../assets/PhotoSlider/IMIDZ_4.jpg";
import slider4 from "../assets/PhotoSlider/IMIDZ_6.jpg";

export default {
  components: { PhotoSlider },
  data: () => ({
    slides: [slider1, slider2, slider3, slider4],
    description: [
      {
        title: "",
        text: "Snažíme sa o zachovanie tradičného mäsiarskeho remesla. Máme v úcte ručnú poctivú prácu, pôvodné receptúry a výrobné postupy. Preto sa u nás mäso nenastrekuje, sušeným klobásam a salámom necháváme dostatočný čas na vyzretie a všetky produkty prechádzajú rukami našich šikovných mäsiarskych majstrov.",
        highlight: "",
      },
      {
        title: "",
        text: "Naše produkty obsahujú skutočné mäso, žiadny separát, či iný odpad z výroby. Nemusíme pridávať žiadne sušené náhrady bielkovín na zvýšenie ich obsahu. Šunky sú vyrobené z chudých častí bravčoviny, morčacích pŕs, či hydinových stehien.",
        highlight: "",
      },
      {
        title: "",
        text: "Párky, šunky, špekáčiky, klobásy... Po ochutnaní našich produktov sa vrátite v čase do doby, keď všetko chutilo a voňalo mäsom, prírodnými koreninami a jemným zaúdením. Keď ste nemuseli čítať zloženie, keď nemusel byť na obale percentuálny podiel mäsa, pretože všetko bolo mäsové.",
        highlight: "",
      },
      {
        title: "",
        text: "Našich dodávateľov si starostlivo vyberáme. Veď na začiatku každého chutného mäsového výrobku je kvalitné mäso. A nielen mäso, ale aj kvalita a zloženie korenín má svoj význam...",
        highlight: "",
      },
    ],
    controls: true,
    indicators: true,
    interval: 4000,
  }),
};
</script>

<style scoped>
.photo-slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.photo-slider .slide {
  position: relative;
}

.photo-slider .slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.photo-slider .slide:hover::before {
  opacity: 0.7;
}

.highlighted {
  color: #9f000d;
}
</style>
